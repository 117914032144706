import locale9ec66352 from '../../locales/en.json'

export const Constants = {
  COMPONENT_OPTIONS_KEY: "nuxtI18n",
  STRATEGIES: {"PREFIX":"prefix","PREFIX_EXCEPT_DEFAULT":"prefix_except_default","PREFIX_AND_DEFAULT":"prefix_and_default","NO_PREFIX":"no_prefix"},
}
export const nuxtOptions = {
  isUniversalMode: false,
  trailingSlash: undefined,
}
export const options = {
  vueI18n: {"locale":"es","fallbackLocale":"en"},
  vueI18nLoader: false,
  locales: [{"iso":"en-US","file":"en.json","text":"EN","value":"en","code":"en","name":"English"},{"iso":"es-MX","file":"es.json","text":"ES","value":"es","code":"es","name":"Español"},{"iso":"es-MX","file":"es-MTY.json","text":"ES-MTY","value":"es-MTY","code":"es-MTY","name":"Español MTY"},{"iso":"es-MX","file":"es-NL.json","text":"ES-NL","value":"es-NL","code":"es-NL","name":"Español NL"},{"iso":"es-AR","file":"es-AR.json","text":"ES-AR","value":"es-AR","code":"es-AR","name":"Español Argentino"}],
  defaultLocale: "es",
  defaultDirection: "ltr",
  routesNameSeparator: "___",
  defaultLocaleRouteNameSuffix: "default",
  sortRoutes: true,
  strategy: "no_prefix",
  lazy: true,
  langDir: "/opt/atlassian/pipelines/agent/build/locales",
  rootRedirect: null,
  detectBrowserLanguage: {"alwaysRedirect":true,"cookieCrossOrigin":false,"cookieDomain":null,"cookieKey":"i18n_redirected_os-arg-lujan-de-cuyo","cookieSecure":false,"fallbackLocale":"en","onlyOnNoPrefix":false,"onlyOnRoot":false,"useCookie":true},
  differentDomains: false,
  seo: false,
  baseUrl: "",
  vuex: {"moduleName":"i18n","syncLocale":false,"syncMessages":false,"syncRouteParams":true},
  parsePages: true,
  pages: {},
  skipSettingLocaleOnNavigate: false,
  beforeLanguageSwitch: () => null,
  onBeforeLanguageSwitch: () => {},
  onLanguageSwitched: () => null,
  normalizedLocales: [{"iso":"en-US","file":"en.json","text":"EN","value":"en","code":"en","name":"English"},{"iso":"es-MX","file":"es.json","text":"ES","value":"es","code":"es","name":"Español"},{"iso":"es-MX","file":"es-MTY.json","text":"ES-MTY","value":"es-MTY","code":"es-MTY","name":"Español MTY"},{"iso":"es-MX","file":"es-NL.json","text":"ES-NL","value":"es-NL","code":"es-NL","name":"Español NL"},{"iso":"es-AR","file":"es-AR.json","text":"ES-AR","value":"es-AR","code":"es-AR","name":"Español Argentino"}],
  localeCodes: ["en","es","es-MTY","es-NL","es-AR"],
}

export const localeMessages = {
  'en.json': () => Promise.resolve(locale9ec66352),
  'es.json': () => import('../../locales/es.json' /* webpackChunkName: "lang-es.json" */),
  'es-MTY.json': () => import('../../locales/es-MTY.json' /* webpackChunkName: "lang-es-MTY.json" */),
  'es-NL.json': () => import('../../locales/es-NL.json' /* webpackChunkName: "lang-es-NL.json" */),
  'es-AR.json': () => import('../../locales/es-AR.json' /* webpackChunkName: "lang-es-AR.json" */),
}
